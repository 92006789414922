<template>
<v-container></v-container>
</template>

<script>
import {LOGIN_SHOPIFY} from "@/store/actions.type";
import {mapState} from "vuex";
export default {
  name: "ShopifyEntry",
  computed: {
    ...mapState("shop", ["currentShop"]),
  },
  created() {
    let storeName = this.$route.query.shop;
    this.$store.dispatch(`auth/${LOGIN_SHOPIFY}`, {storeName})
      .then((data) => {
        if (data.success === true && typeof data.redirect_url != "undefined"){
          let redirectUrl = data.redirect_url;
          if (data.is_onboarding) {
            redirectUrl += process.env.VUE_APP_SHOPIFY_RESPONSE_REDIRECT_URI;
          } else {
            if (typeof (data.is_v1) != 'undefined') {
              // HACK - TO BE REMOVE AFTER FULL MIGRATION IS DONE 2021-09-07
              redirectUrl = 'https://app.syncio.co/?shop='+storeName;
            } else {
              redirectUrl += process.env.VUE_APP_BASE_URL+'/dashboard';
            }
          }
          window.location.href = redirectUrl;
        } else {
          this.$router.push('/dashboard')
        }
      });
  },
}
</script>

<style scoped>
.container-max-width{
  max-width: 1200px;
  min-width: 768px !important;
}
</style>